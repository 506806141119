import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

const ConsentBanner: React.FC = () => (
  <CookieConsent
    location="bottom"
    buttonText="I understand"
    declineButtonText="I decline"
    enableDeclineButton
    flipButtons
    buttonClasses="btn btn-primary"
    declineButtonClasses="btn btn-primary"
    buttonStyle={{ backgroundColor: "#0d6efd", color: 'white', borderRadius: '4px' }}
    declineButtonStyle={{ backgroundColor: "#0d6efd", color: 'white', borderRadius: '4px' }}
    onAccept={() => {
      Cookies.set('gdpr-analytics-enabled', 'true');
      window.location.reload();
    }}
    onDecline={() => {
      Cookies.set('gdpr-analytics-enabled', 'false');
      window.location.reload();
    }}
  >
    We use cookies to ensure you get the best experience on our website. Learn more in our{" "}
    <a href="/privacy">privacy policy</a>.
  </CookieConsent>
);

export default ConsentBanner;
